import "./Offer.scss";
import Button from "../../Atoms/Button/index.jsx";
import { Component } from "react";
import { currency } from "Helpers/format";
import { Link } from "react-router-dom";

export default class Offer extends Component {
  render() {
    const { name, color, url, image, offerType, discount, units } = this.props;

    let className = "offer" + (color ? " offer--" + color : "");

    let buttonText;
    let offerTypeText;
    let unitsText;
    if (offerType === "opportunity") {
      if (units > 1) {
        buttonText = "Ver Unidades";
      } else {
        buttonText = "Reservar";
      }
    } else {
      buttonText = "Ver más";
    }

    if (units > 1) {
      unitsText =
        "Hay " +
        units +
        " unidades disponibles con precio especial. Selecciona la unidad que te interese y reservá online en solo unos minutos.";
    } else {
      unitsText =
        "Hay " +
        units +
        " unidad disponible con precio especial. Reservala online en solo unos minutos.";
    }

    var offerElement;
    switch (offerType) {
      case "opportunity":
        offerTypeText = "Oportunidad";
        offerElement = (
          <Link to={url} className={className}>
            <div className="offer__hero">
              <div className="offer__image">
                <img src={image} alt={name} />
              </div>
              <div className="offer__name">
                {name}
                <div className="offer__type">{offerTypeText}</div>
              </div>
            </div>
            <div className="offer__content">
              <p className="p ">
                <strong> {currency(discount)} de descuento</strong>
              </p>
              <p className="p p-s">{unitsText}</p>

              <div className="offer__content__button">
                <Button small color="ghost">
                  {buttonText}
                </Button>
              </div>
            </div>
          </Link>
        );
        break;
      case "web":
        offerTypeText = "Descuento web";
        offerElement = (
          <Link to={url} className={className}>
            <div className="offer__hero">
              <div className="offer__image">
                <img src={image} alt={name} />
              </div>
              <div className="offer__name">
                {name}
                <div className="offer__type">{offerTypeText}</div>
              </div>
            </div>
            <div className="offer__content">
              <p className="p ">
                <strong> {currency(discount)} de descuento</strong>
              </p>

              <div className="offer__content__button">
                <Button small color="ghost">
                  {buttonText}
                </Button>
              </div>
            </div>
          </Link>
        );
        break;

      default:
        offerElement = (
          <a href={url} className={className}>
            <div className="offer__hero">
              <div className="offer__image">
                <img src={image} alt={name} />
              </div>
              <div className="offer__name">{name}</div>
            </div>
            <div className="offer__content">
              <div className="offer__content__button">
                <Button small color="ghost">
                  {buttonText}
                </Button>
              </div>
            </div>
          </a>
        );
    }

    return <div>{offerElement}</div>;
  }
}
