import { Component } from "react";
import "./Button.scss";

export default class Button extends Component {
  render() {
    const { children, color, small, ...rest } = this.props;

    let className =
      "button" +
      (color ? " button--" + color : "") +
      (small ? " button--small" : "");

    return (
      <button className={className} {...rest}>
        <span>{children}</span>
      </button>
    );
  }
}
