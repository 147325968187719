import Accessory from 'Models/Accessory';
import axios from 'axios';

export async function getCategories() {
  try {
    const response = await axios.get('/api/vw/accessory-categories');

    return response.data.data;
  } catch (e) {
    console.error(e);
  }

  return [];
}

export async function getModels() {
  try {
    const response = await axios.get('/api/vw/accessory-models');

    return response.data.data;
  } catch (e) {
    console.error(e);
  }

  return [];
}

export async function getAccessories(page, typeId, modelId, search) {
  const params = new URLSearchParams();
  params.set('page', page);
  params.set('accessory_type_id', typeId);

  if (modelId) {
    params.set('model_id', modelId);
  }
  if (search) {
    params.set('search', search);
  }

  try {
    const response = await axios.get(`/api/vw/accessories?${params.toString()}`);

    const meta = response.data;
    meta.data = meta.data.map(data => new Accessory(data));

    return meta;
  } catch (e) {
    console.error(e);
  }

  return [];
}

export async function request(data, products) {
  data.products = products.reduce((requestProducts, product) => {
    let requestProduct = requestProducts.find(({ id }) => id === product.id);

    if (!requestProduct) {
      requestProduct = { id: product.id, quantity: 0 };

      requestProducts.push(requestProduct);
    };

    requestProduct.quantity+= 1;

    return requestProducts;
  }, []);

  try {
    await axios.post('/api/vw/accessory-request', { data });

    return true;
  } catch (e) {
    console.error(e);
  }

  return false;
}
