import axios from "axios";
import Footer from "Components/Layout/Footer/index.jsx";
import Breadcrumbs from "Components/Layout/Breadcrumbs/index.jsx";
import Section from "Components/Layout/Section/index.jsx";
import MainLink from "Components/Atoms/MainLink/index.jsx";
import Tabs from "Components/Atoms/Tabs/index.jsx";
import Model from "Components/Molecules/Model/index.jsx";
import Stage from "Components/Layout/Stage/index.jsx";
import Intro from "Components/Layout/Editorials/Intro/index.jsx";
import TwoColumns from "Components/Layout/Editorials/TwoColumns/index.jsx";
import TechnicalData from "Components/Layout/Editorials/TechnicalData/index.jsx";
import Basic from "Components/Layout/Editorials/Basic/index.jsx";
import Button from "Components/Atoms/Button/index.jsx";
import { useState, useEffect } from "react";
import {
  useFamily,
  useSubfamilies,
  useOffers,
} from "Components/Context/Parameters";
import { useParams, Redirect, Link } from "react-router-dom";
import { currency } from "Helpers/format";
import {Helmet} from "react-helmet";

function Models() {
  const params = useParams();
  const family = useFamily(params.family);
  const subfamilies = useSubfamilies(family);
  const [subfamily, setSubfamily] = useState(
    subfamilies ? subfamilies[0] : null
  );
  const [brochure, setBrochure] = useState(null);
  const offers = useOffers(family);

  useEffect(() => {
    axios
      .get(`/vw/brochure?family_name=${family.url_name}`)
      .then((response) => {
        if (response.data.brochure) {
          setBrochure(response.data.brochure);
        }
      })
      .catch((e) => console.error(e));
  }, [family]);

  useEffect(() => {
    if (!subfamily) {
      setSubfamily(subfamilies[0]);
    }
  }, [subfamilies]); // eslint-disable-line

  if (!family) {
    return <Redirect to="/modelos" />;
  }

  let breadcrumbsList = [
    { id: 1, name: "Página de inicio", link: "/" },
    { id: 2, name: "Modelos", link: "/modelos" },
    { id: 3, name: family.name, link: `/modelos/${family.url_name}` },
  ];

  let techDataList = [];

  let warrantyTitle = (
    <>
      Todos los {family.name}
      <strong> tienen una garantía de {family.warranty}</strong>
    </>
  );

  let basicDiscounts = (
    <Basic
      layout="basic-12"
      image={family.discount_image}
      title="Unidades con precio especial"
      subtitle="Oportunidades "
      description={`Consultá nuestros vehículos 0km con descuentos especiales. Selecciona la unidad que te interese y reservá online en solo unos minutos.`}
    >
      <MainLink url="/oportunidades">Ver oportunidades</MainLink>
    </Basic>
  );

  if (offers.length) {
    const amount = offers.length;

    const discountTitle = (
      <>
        Hay {amount} {family.name}
        <strong> con precio especial. </strong>
      </>
    );

    basicDiscounts = (
      <Basic
        layout="basic-12"
        image={family.discount_image}
        title={discountTitle}
        subtitle="Oportunidades "
        description={`Hay ${amount} ${
          amount > 1 ? "unidades disponibles" : "unidad disponible"
        } con precio especial. Selecciona la unidad que te interese y reservá online en solo unos minutos.`}
      >
        <MainLink url={`/modelos/${family.url_name}/oportunidades`}>
          Ver unidades
        </MainLink>
      </Basic>
    );
  }

  let basicWarranty = (
    <Basic
      layout="basic-6"
      image={family.warranty_image}
      title={warrantyTitle}
      subtitle="Garantía "
      description={`Tener un  es garantía de tranquilidad de seguridad y de que vas a tener un auto para disfrutar en cada momento. Para realizar cualquier tipo de consulta relacionada a la garantía de tu vehículo Volkswagen, acercate a tu concesionario o contactanos online, por teléfono o WhatsApp.`}
    >
      <MainLink
        icon
        url="https://www.youtube.com/embed/mSI5MgzXpaU"
        target="_blank"
        rel="noopener noreferrer"
      >
        Ver video
      </MainLink>
    </Basic>
  );

  let versionTabElements = subfamilies.map((subfamily) => ({
    text: subfamily.name,
    onClick: () => setSubfamily(subfamily),
  }));

  if (subfamily?.discount) {
    techDataList.push({
      name: "Descuento Web",
      value: currency(subfamily.discount),
    });
  }

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - {family.name}</title>
        <meta name="description" content="Vehículos 0km - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      <Stage
        title={family.name}
        image={family.hero}
        cta="Conocelo"
        url={`#${family.name}`}
      />
      <TechnicalData dataList={techDataList} catalog={brochure} />
      <div id={family.name} />
      <Breadcrumbs linkList={breadcrumbsList} withCurrent />

      <Intro
        title={family.name}
        description={
          <div dangerouslySetInnerHTML={{ __html: family.description }}></div>
        }
      />
      <TwoColumns column1={basicDiscounts} column2={basicWarranty} />
      {subfamily && (
        <>
          <Intro title="Elegí tu versión" titleOnly small />
          <Section>
            <div className="grid">
              <div className="col20 s3">
                <Tabs
                  active={subfamilies.indexOf(subfamily)}
                  elements={versionTabElements}
                />
              </div>
            </div>
          </Section>
          <Section>
            <div className="grid" id="tab-content">
              <div className="col10 s3">
                <Model model={family} onlyImage />
              </div>
              <div className="col8 s14">
                <Link
                  to={`/modelos/${family.url_name}/${subfamily.url_name}/unidades`}
                >
                  <Button>Ver Unidades</Button>
                </Link>
                <h4 className="h4">
                  <strong>
                    Características de la versión {subfamily.name}
                  </strong>
                </h4>
                <ul className="list list--characteristics">
                  {subfamily.features.map((feature, i) => (
                    <li key={i}>
                      <span>
                        <svg
                          aria-hidden="true"
                          viewBox="0 0 12 12"
                          width="12"
                          height="12"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          role="img"
                        >
                          <path d="M4.39 11.39a.81.81 0 01-.51-.18L0 8.11l.88-1 3.39 2.67L10.86.39l1.14.76L4.88 11.3l-.37.08z"></path>
                        </svg>
                      </span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Section>
        </>
      )}
      <Footer />
    </>
  );
}

export default Models;
