export default class Accessory {
  constructor(props) {
    for (let key in props) {
      if (props.hasOwnProperty(key)) {
        this[key] = props[key];
      }
    }
  }

  get has_discount() {
    return this.price_special > 0 || this.price_discount > 0;
  }

  get final_price() {
    if (this.price_special) {
      return this.price_special;
    }

    return this.price_discount
      ? this.price - (this.price * this.price_discount) / 100
      : this.price;
  }
}
