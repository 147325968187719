import ImageGol from "images/models/gol.webp";
import ImageUp from "images/models/up.webp";
import ImagePolo from "images/models/polo.webp";
import ImageGolf from "images/models/golf.webp";
import ImageNivus from "images/models/nivus.png";
import ImageVoyage from "images/models/voyage.webp";
import ImageVirtus from "images/models/virtus.webp";
import ImageTCross from "images/models/t-cross.webp";
import ImageVento from "images/models/vento.png";
import ImageTiguan from "images/models/tiguan.png";
import ImageTouareg from "images/models/touareg.png";
import ImageSaveiro from "images/models/saveiro.png";
import ImageAmarok from "images/models/amarok.png";
import ImageNewAmarok from "images/models/nueva-amarok.webp";
import ImageTaos from "images/models/taos.webp";

import StageNewAmarok from "images/photos/details/models/nueva-amarok/amarok-stage.webp";
import StageAmarok from "images/photos/Stages/models/amarok.jpg";
import StageGol from "images/photos/Stages/models/gol.jpg";
import StageUp from "images/photos/Stages/models/up.jpg";
import StageGolf from "images/photos/Stages/models/golf.jpg";
import StageNivus from "images/photos/Stages/models/nivus.jpg";
import StageSaveiro from "images/photos/Stages/models/saveiro.jpg";
import StagePolo from "images/photos/Stages/models/polo.jpg";
import StageTiguan from "images/photos/Stages/models/tiguan.jpg";
import StageTouareg from "images/photos/Stages/models/touareg.jpg";
import StageTCross from "images/photos/Stages/models/t-cross.jpg";
import StageVento from "images/photos/Stages/models/vento.jpg";
import StageVirtus from "images/photos/Stages/models/virtus.jpg";
import StageVoyage from "images/photos/Stages/models/voyage.jpg";
import StageTaos from "images/photos/Stages/models/taos.webp";

import DiscountNewAmarok from "images/photos/details/models/nueva-amarok/amarok-discount.webp";
import DiscountAmarok from "images/photos/details/models/amarok/amarok-discount.jpg";
import DiscountGol from "images/photos/details/models/gol/gol-discount.jpg";
import DiscountGolf from "images/photos/details/models/golf/golf-discount.jpg";
import DiscountNivus from "images/photos/details/models/nivus/nivus-discount.jpg";
import DiscountPolo from "images/photos/details/models/polo/polo-discount.jpg";
import DiscountSaveiro from "images/photos/details/models/saveiro/saveiro-discount.jpg";
import DiscountTCross from "images/photos/details/models/t-cross/t-cross-discount.jpg";
import DiscountTiguan from "images/photos/details/models/tiguan/tiguan-discount.jpg";
import DiscountTouareg from "images/photos/details/models/touareg/touareg-discount.jpg";
import DiscountUp from "images/photos/details/models/up/up-discount.jpg";
import DiscountVento from "images/photos/details/models/vento/vento-discount.jpg";
import DiscountVirtus from "images/photos/details/models/virtus/virtus-discount.jpg";
import DiscountVoyage from "images/photos/details/models/voyage/voyage-discount.jpg";
import DiscountTaos from "images/photos/details/models/taos/taos-discount.webp";

import WarrantyNewAmarok from "images/photos/details/models/nueva-amarok/amarok-warranty.webp";
import WarrantyAmarok from "images/photos/details/models/amarok/amarok-warranty.jpg";
import WarrantyGol from "images/photos/details/models/gol/gol-warranty.jpg";
import WarrantyGolf from "images/photos/details/models/golf/golf-warranty.jpg";
import WarrantyNivus from "images/photos/details/models/nivus/nivus-warranty.jpg";
import WarrantyPolo from "images/photos/details/models/polo/polo-warranty.jpg";
import WarrantySaveiro from "images/photos/details/models/saveiro/saveiro-warranty.jpg";
import WarrantyTCross from "images/photos/details/models/t-cross/t-cross-warranty.jpg";
import WarrantyTiguan from "images/photos/details/models/tiguan/tiguan-warranty.jpg";
import WarrantyTouareg from "images/photos/details/models/touareg/touareg-warranty.jpg";
import WarrantyUp from "images/photos/details/models/up/up-warranty.jpg";
import WarrantyVento from "images/photos/details/models/vento/vento-warranty.jpg";
import WarrantyVirtus from "images/photos/details/models/virtus/virtus-warranty.jpg";
import WarrantyVoyage from "images/photos/details/models/voyage/voyage-warranty.jpg";
import WarrantyTaos from "images/photos/details/models/taos/taos-warranty.webp";

export function getImage(vehicle) {
  const name = vehicle.family ?? vehicle.name;

  switch (name.toLowerCase()) {
    case "nueva amarok":
      return ImageNewAmarok;
    case "amarok":
      return ImageAmarok;
    case "gol":
      return ImageGol;
    case "golf":
      return ImageGolf;
    case "nivus":
      return ImageNivus;
    case "nuevo touareg":
    case "touareg":
      return ImageTouareg;
    case "polo":
      return ImagePolo;
    case "saveiro":
      return ImageSaveiro;
    case 't-cross':
    case "tcross":
      return ImageTCross;
    case 'tiguan allspace':
    case "tiguan":
      return ImageTiguan;
    case "up!":
      return ImageUp;
    case "vento":
      return ImageVento;
    case "virtus":
      return ImageVirtus;
    case "voyage":
      return ImageVoyage;
    case "taos":
    case "nuevo taos":
    case "nueva taos":
      return ImageTaos;
    default:
      console.log(`Image for ${name} not found.`);

      return "";
  }
}

export function getHero(vehicle) {
  const name = vehicle.family ?? vehicle.name;

  switch (name.toLowerCase()) {
    case "nueva amarok":
      return StageNewAmarok;
    case "amarok":
      return StageAmarok;
    case "gol":
      return StageGol;
    case "golf":
      return StageGolf;
    case "nivus":
      return StageNivus;
    case "nuevo touareg":
    case "touareg":
      return StageTouareg;
    case "polo":
      return StagePolo;
    case "saveiro":
      return StageSaveiro;
    case 't-cross':
    case "tcross":
      return StageTCross;
    case 'tiguan allspace':
    case "tiguan":
      return StageTiguan;
    case "up!":
      return StageUp;
    case "vento":
      return StageVento;
    case "virtus":
      return StageVirtus;
    case "voyage":
      return StageVoyage;
      case "taos":
      case "nuevo taos":
      case "nueva taos":
        return StageTaos;
    default:
      console.log(`Stage for ${name} not found.`);

      return "";
  }
}

export function getWarranty(vehicle) {
  const name = vehicle.family ?? vehicle.name;

  switch (name.toLowerCase()) {
    case "nueva amarok":
      return WarrantyNewAmarok;
    case "amarok":
      return WarrantyAmarok;
    case "gol":
      return WarrantyGol;
    case "golf":
      return WarrantyGolf;
    case "nivus":
      return WarrantyNivus;
    case "nuevo touareg":
    case "touareg":
      return WarrantyTouareg;
    case "polo":
      return WarrantyPolo;
    case "saveiro":
      return WarrantySaveiro;
    case 't-cross':
    case "tcross":
      return WarrantyTCross;
    case 'tiguan allspace':
    case "tiguan":
      return WarrantyTiguan;
    case "up!":
      return WarrantyUp;
    case "vento":
      return WarrantyVento;
    case "virtus":
      return WarrantyVirtus;
    case "voyage":
      return WarrantyVoyage;
      case "taos":
      case "nuevo taos":
      case "nueva taos":
        return WarrantyTaos;
    default:
      console.log(`Warranty for ${name} not found.`);

      return "";
  }
}

export function getDiscount(vehicle) {
  const name = vehicle.family ?? vehicle.name;

  switch (name.toLowerCase()) {
    case "nueva amarok":
      return DiscountNewAmarok;
    case "amarok":
      return DiscountAmarok;
    case "gol":
      return DiscountGol;
    case "golf":
      return DiscountGolf;
    case "nivus":
      return DiscountNivus;
    case "nuevo touareg":
    case "touareg":
      return DiscountTouareg;
    case "polo":
      return DiscountPolo;
    case "saveiro":
      return DiscountSaveiro;
    case 't-cross':
    case "tcross":
      return DiscountTCross;
    case 'tiguan allspace':
    case "tiguan":
      return DiscountTiguan;
    case "up!":
      return DiscountUp;
    case "vento":
      return DiscountVento;
    case "virtus":
      return DiscountVirtus;
    case "voyage":
      return DiscountVoyage;
      case "taos":
      case "nuevo taos":
      case "nueva taos":
        return DiscountTaos;
    default:
      console.log(`Discount for ${name} not found.`);

      return "";
  }
}
