import Footer from "Components/Layout/Footer/index.jsx";
import Breadcrumbs from "Components/Layout/Breadcrumbs/index.jsx";
import Section from "Components/Layout/Section/index.jsx";
import Model from "Components/Molecules/Model/index.jsx";
import { useFamilies } from "Components/Context/Parameters";
import {Helmet} from "react-helmet";

function Models() {
  const families = useFamilies();

  let breadcrumbsList = [
    { id: 1, name: "Página de inicio", link: "/" },
    { id: 2, name: "Modelos", link: "/modelos" },
  ];

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - Vehículos 0km</title>
        <meta name="description" content="Vehículos 0km - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      <Section first>
        <Breadcrumbs linkList={breadcrumbsList} withCurrent />
        <div className="grid ">
          <div className="col20 s3">
            <h2>Todos los modelos</h2>
          </div>
        </div>
      </Section>
      <Section>
        <div className="grid">
          <div className="col20 s3 col24m">
            <div className="grid grid--models">
              {families.map((family) => (
                <Model
                  key={family.id}
                  model={family}
                  url={`/modelos/${family.url_name}`}
                />
              ))}
            </div>
          </div>
        </div>
      </Section>
      <Footer />
    </>
  );
}

export default Models;
