const LinkingPos = ({ amount, transaction }) => {
  return (
    <iframe
      title="Pago"
      src={`https://linkingpos.com/external_payments/new?access_token=gcu1tr29S7Tf7DPkiJR3KoRy&import=${amount}&client_ref=${transaction.code}`}
      style={{ width: "100%", height: "820px", overflowY: "hidden" }}
      scrolling="no"
      frameBorder="0"
    ></iframe>
  );
};

export default LinkingPos;
