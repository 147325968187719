import { Component } from "react";
import Button from "Components/Atoms/Button/index.jsx";
import "./SidePanel.scss";

function SidePanel({ children, open, backButton, large, small, onBackButtonClick, onCloseClick }) {
  let className =
    "side-panel" +
    (open ? " side-panel--open" : "") +
    (small ? " side-panel--small" : "") +
    (large ? " side-panel--large" : "");
  var backElement;
  if (backButton) {
    backElement = (
      <div className="side-panel__header__back-button">
        <Button small onClick={onBackButtonClick}>{backButton}</Button>{" "}
      </div>
    );
  }

  var panelElement;

  panelElement = (
    <div className="side-panel__body">
      <div className="side-panel__header">
        {backElement}
        <div className="side-panel__header__close" onClick={onCloseClick}>
          <div className="side-panel__header__close__button" />
        </div>
      </div>
      <div className="side-panel__content">{children}</div>
    </div>
  );

  return (
    <div className={className}>
      {panelElement}
      <div className="side-panel__overlay"></div>
    </div>
  );
}

export default SidePanel;
