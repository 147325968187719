import { Component } from "react";
import Button from "../../../Atoms/Button/index.jsx";
import "./TechnicalData.scss";

export default class TechnicalData extends Component {
  render() {
    const { dataList, catalog, small, center } = this.props;

    let className =
      "technical-data" +
      (small ? " technical-data--small" : "") +
      (center ? " technical-data--center" : "");

    var listElement;
    if (dataList) {
      listElement = dataList.map((item, i) => (
        <div className="technical-data__item" key={i}>
          <div className="technical-data__item__name">{item.name}</div>
          <div className="technical-data__item__value">
            {item.value} <span>{item.measure}</span>
          </div>
        </div>
      ));
    }

    var catalogElement = (
      <div className="technical-data__catalog">
        <div className="technical-data__catalog__line"></div>
      </div>
    );
    if (catalog) {
      catalogElement = (
        <div className="technical-data__catalog">
          <div className="technical-data__catalog__line"></div>
          <div className="technical-data__catalog__button">
            <a href={catalog} target="_blank" rel="noopener noreferrer">
              <Button color="ghost">Descarga el catálogo</Button>
            </a>
          </div>
        </div>
      );
    }

    return (
      <div className={className}>
        <div className="grid">
          <div className="col22 s2">{listElement}</div>
        </div>
        <div className="grid">
          <div className="col22 s2">{catalogElement}</div>
        </div>
      </div>
    );
  }
}
