import axios from "axios";
import Footer from "Components/Layout/Footer/index.jsx";
import Breadcrumbs from "Components/Layout/Breadcrumbs/index.jsx";
import Section from "Components/Layout/Section/index.jsx";
import SectionHero from "Components/Layout/SectionHero/index.jsx";
import Intro from "Components/Layout/Editorials/Intro/index.jsx";
import TechnicalData from "Components/Layout/Editorials/TechnicalData/index.jsx";
import Button from "Components/Atoms/Button/index.jsx";
import FormRow from "Components/Atoms/Forms/FormRow/index.jsx";
import FormField from "Components/Atoms/Forms/FormField/index.jsx";
import TextField from "Components/Atoms/Forms/TextField/index.jsx";
import Select from "Components/Atoms/Forms/Select/index.jsx";
import CheckboxGroup from "Components/Atoms/Forms/CheckboxGroup/index.jsx";
import Checkbox from "Components/Atoms/Forms/Checkbox/index.jsx";
import LinkingPos from "Components/Molecules/Payment/LinkingPos";
import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useFamily, useParameter, useStockItem } from "Components/Context/Parameters";
import { currency } from "Helpers/format";
import {Helmet} from "react-helmet";
import preciosJustos from "images/precios-justos.png";

const maritalStatus = [
  { value: 2, name: "Casado/a" },
  { value: 3, name: "Divorciado/a" },
  { value: 1, name: "Soltero/a" },
  { value: 4, name: "Viudo/a" },
];

function Buy() {
  const params = useParams();
  const family = useFamily(params.family);
  const vehicle = useStockItem(params.id);
  const [promotion, setPromotion] = useState(undefined);
  const [code, setCode] = useState("");
  const [transaction, setTransaction] = useState(undefined);
  const [error, setError] = useState(false);
  const [request, setRequest] = useState({
    name: "",
    lastname: "",
    marital_status_id: "1",
    spouse_name: "",
    address: "",
    telephone: "",
    dni: "",
    birthdate: "",
    email: "",
  });
  const bookingPrice = useParameter("booking_price") || 200000;

  useEffect(() => {
    if (vehicle) {
      setRequest({
        name: "",
        lastname: "",
        marital_status_id: "1",
        spouse_name: "",
        address: "",
        telephone: "",
        dni: "",
        birthdate: "",
        email: "",
        vehicle: vehicle.name,
        promotion_code: "",
        stock_id: vehicle.id,
        brand_id: 1,
        model: vehicle.name,
        color: vehicle.color,
        chasis: vehicle.chassis,
        fullprice: vehicle.getPrice(promotion),
        family_discount: vehicle.special_discount ? 0 : family.discount,
        special_discount: vehicle.special_discount,
        accept_terms: false,
        payment_provider: "linkingpos",
      });
    }
  }, [vehicle]); // eslint-disable-line

  if (vehicle === undefined) {
    return null;
  }

  if (vehicle === null) {
    return NotFound();
  }

  function checkPromotion() {
    setPromotion(undefined);

    axios
      .get(`/api/promotion?code=${code}&family=${family.name}&chassis=${vehicle.chassis}&brand_id=1`)
      .then((response) => {
        setPromotion(response.data.data);

        setRequest({ ...request, fullprice: vehicle.getPrice(promotion), promotion_code: code });
      })
      .catch((e) => {
        console.error(e);
        setPromotion(null);
      })
      .finally(() => {
        setCode("");
      });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setTransaction(null);
    setError(false);

    try {
      const result = await axios.post("/api/vw/purchase", { data: request });

      if (result.data.transaction) {
        setTransaction(result.data.transaction);
      }
    } catch (e) {
      console.error(e);

      setError(true);
      setTransaction(undefined);
    }
  }

  function handleChange(e) {
    setRequest({ ...request, [e.currentTarget.name]: e.currentTarget.value });
  }

  const step1 = [<strong key="step-1">Paso 1:</strong>, " Resumen"];
  const step2 = [<strong key="step-2">Paso 2:</strong>, " Completá tus datos"];
  const step3 = [<strong key="step-3">Paso 3:</strong>, " Confirmación"];
  const step4 = [<strong key="step-4">Paso 4:</strong>, " Pagá tu reserva"];

  const ready =
    request.name &&
    request.lastname &&
    request.marital_status_id &&
    request.telephone &&
    request.dni &&
    request.birthdate &&
    request.email &&
    request.accept_terms;

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - Reservar vehículo 0km online</title>
        <meta name="description" content="Reservá tu próximo vehículo 0km a través de nuestro sitio web - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      <SectionHero subtitle="Unidades" title={
          <>
            <strong>Reservá online</strong>
            <br />
            {vehicle.name}
          </>
        } image={family.hero}
      />
      <TechnicalData
        dataList={[
          {
            id: 1,
            name: "Precio",
            value: currency(vehicle.fullprice_special || vehicle.fullprice),
          },
          { id: 2, name: "Color", value: vehicle.color },
          { id: 3, name: "Número de Chasis", value: vehicle.chassis },
        ]}
      />
      <Breadcrumbs
        linkList={[
          { id: 1, name: "Página de inicio", link: "/" },
          { id: 2, name: "Modelos", link: "/modelos" },
          { id: 3, name: family.name, link: `/modelos/${family.url_name}` },
          {
            id: 4,
            name: "Reserva Online",
            link: `/modelos/${family.url_name}/${vehicle.id}`,
          },
        ]}
        withCurrent
      />

      {!transaction && (
        <form onSubmit={handleSubmit}>
          <Section>
            <Intro title={step1} subtitle="Reserva" />
            <div className="grid">
              <div className="col col12 s7">
                {vehicle.govPrice && (
                  <img src={preciosJustos} alt="Precios justos" width="auto" height="100" style={{ margin: '0 auto', display: 'block', marginBottom: '1em' }} />
                )}
                <table className="table" cellSpacing="0" cellPadding="0" width="100%">
                  <thead>
                    <tr>
                      <th width="50%">Precio final </th>
                      <th className="td-right">{currency(vehicle.getPrice(promotion))}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Precio de lista</td>
                      <td className="td-right">{currency(vehicle.list_price)}</td>
                    </tr>
                    <tr>
                      <td>Entrega</td>
                      <td className="td-right">{currency(vehicle.delivery_price)}</td>
                    </tr>
                    <tr>
                      <td>Patentamiento</td>
                      <td className="td-right">{currency(vehicle.license_price)}</td>
                    </tr>
                    {!vehicle.special_discount && vehicle.discount && (
                      <tr>
                        <td>Descuento especial web</td>
                        <td className="td-right">-{currency(vehicle.discount)}</td>
                      </tr>
                    )}
                    {vehicle.special_discount && (
                      <tr>
                        <td>Descuento especial</td>
                        <td className="td-right">-{currency(vehicle.special_discount)}</td>
                      </tr>
                    )}
                    {promotion ? (
                      <tr>
                        <td>Código de descuento</td>
                        <td className="td-right">-{currency(vehicle.getDiscountFromCoupon(promotion))}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>Código de descuento</td>
                        <td className="td-right">
                          <FormRow>
                            <FormField inline>
                              <TextField
                                placeholder="XXX-XXXX"
                                value={code}
                                onChange={(e) => setCode(e.currentTarget.value)}
                              />
                            </FormField>
                            <FormField inline>
                              <Button onClick={checkPromotion}>Aplicar cupón</Button>
                            </FormField>
                          </FormRow>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Section>
          <Section>
            <Intro title={step2} />
            <div className="grid">
              <div className="col col10 s7">
                <FormRow>
                  <FormField label="Nombre" inline>
                    <TextField name="name" required onChange={handleChange} value={request.name} />
                  </FormField>
                  <FormField label="Apellido" inline>
                    <TextField name="lastname" required onChange={handleChange} value={request.lastname} />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField label="DNI" small inline>
                    <TextField
                      placeholder="Sin puntos"
                      name="dni"
                      required
                      onChange={handleChange}
                      value={request.dni}
                    />
                  </FormField>
                  <FormField label="Fecha de nacimiento" inline>
                    <TextField
                      placeholder="DD/MM/AAAA"
                      type="date"
                      name="birthdate"
                      required
                      onChange={handleChange}
                      value={request.birthdate}
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField label="Estado Civil" select inline>
                    <Select name="marital_status_id" required onChange={handleChange} value={request.marital_status_id}>
                      {maritalStatus.map(({ value, name }) => (
                        <option value={value} key={value}>
                          {name}
                        </option>
                      ))}
                    </Select>
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField label="Dirección">
                    <TextField name="address" required onChange={handleChange} value={request.address} />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField label="Email" large>
                    <TextField name="email" type="email" required onChange={handleChange} value={request.email} />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField label="Teléfono" mid>
                    <TextField type="tel" name="telephone" required onChange={handleChange} value={request.telephone} />
                  </FormField>
                </FormRow>
              </div>
            </div>
          </Section>

          <Section>
            <Intro title={step3} />
            <div className="grid">
              <div className="col col10 s7">
                {!ready && (
                  <FormRow>
                    <p style={{ color: "red" }}>
                      Completa tus datos personales y aceptá nuestros términos y condiciones para enviar la solicitud.
                    </p>
                  </FormRow>
                )}
                <FormRow>
                  <p>
                    Con solo {currency(bookingPrice)}, que podés pagar con tu tarjeta de crédito en o débito, confirmás tu reserva del
                    vehículo.
                    <br />
                    <br />
                    Dentro de las próximas 48 horas te acercás a nuestro concesionario y concretás la operación, o te
                    reintegramos el 100% de la seña.
                  </p>
                </FormRow>
                <FormRow>
                  <FormField label="Términos y condiciones">
                    <CheckboxGroup>
                      <Checkbox
                        text="Acepto los términos y condiciones"
                        required
                        name="accept_terms"
                        checked={request.accept_terms}
                        onChange={handleChange}
                        value={true}
                      />
                    </CheckboxGroup>
                  </FormField>
                </FormRow>
                <FormRow>
                  {error && (
                    <FormRow>
                      <p style={{ color: "red" }}>
                        Hubo un error al procesar tu solicitud. Por favor intenta nuevamente o{" "}
                        <Link to="/contacto">contactanos</Link>.
                      </p>
                    </FormRow>
                  )}

                  <FormField>
                    {transaction === undefined && (
                      <Button type="submit" disabled={!ready}>
                        Pagar reserva
                      </Button>
                    )}

                    {transaction === null && <p>Enviando solicitud...</p>}
                  </FormField>
                </FormRow>
              </div>
            </div>
          </Section>
        </form>
      )}

      {transaction && (
        <Section>
          <Intro title={step4} />
          <div className="grid">
            <div className="col col10 s7">
              <FormRow>
                <p>
                  Con solo {currency(bookingPrice)}, que podés pagar con tu tarjeta de crédito en o débito, confirmás tu reserva del
                  vehículo.
                  <br />
                  <br />
                  Dentro de las próximas 48 horas te acercás a nuestro concesionario y concretás la operación, o te
                  reintegramos el 100% de la seña.
                </p>
              </FormRow>
              <FormRow>
                <LinkingPos amount={bookingPrice} transaction={transaction} />
              </FormRow>
            </div>
          </div>
        </Section>
      )}

      <Footer />
    </>
  );
}

const NotFound = () => {
  const params = useParams();
  const family = useFamily(params.family);

  const breadcrumbsList = [
    { id: 1, name: "Página de inicio", link: "/" },
    { id: 2, name: "Modelos", link: "/modelos" },
    { id: 3, name: family.name, link: `/modelos/${family.url_name}` },
  ];

  let introTitle = (
    <>
      <strong>Reserva online</strong>
      <br />
      El vehículo seleccionado no existe o ya ha sido reservado.
    </>
  );

  return (
    <>
      <SectionHero type="imageOnly" title="Unidedes" image={family.hero} />
      <Breadcrumbs linkList={breadcrumbsList} withCurrent />

      <Intro title={introTitle} titleOnly />

      <Footer />
    </>
  );
};

export default Buy;
