import { Component } from "react";
import "./Section.scss";

export default class Section extends Component {
  render() {
    const { children, first, ...etc } = this.props;

    let className = "section" + (first ? " section--first" : "");

    return <div className={className} {...etc}>{children}</div>;
  }
}
