import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Modal from "Components/Layout/Modal/index.jsx";
import { useParameter } from "Components/Context/Parameters";

function getSectionFromLocation(pathname) {
    switch (pathname) {
        case "/":
            return "home";
        case "/modelos":
            return "models";
        case "/autoahorro":
            return "savings";
        case "/oportunidades":
            return "offers";
        default:
            return "all";
    }
}

const Popups = () => {
    const [section, setSection] = useState('all');
    const [popupsDisplayed, setPopupsDisplayed] = useState([]);
    const popups = useParameter("popups");
    const location = useLocation();

    useEffect(() => {
        setSection(getSectionFromLocation(location.pathname));
    }, [location]);

    const popupsToDisplay = popups?.filter(popup => {
        return (popup.section === section || popup.section === 'all') && !popupsDisplayed.includes(popup.id);
    });
    const id = popupsToDisplay?.length > 1 ? Math.round(Math.random() * 10 % (popupsToDisplay.length - 1)) : 0;
    const popup = popupsToDisplay?.length ? popupsToDisplay[id] : undefined;

    if (!popup) {
        return null;
    }    

    return (
        <Modal open imageOnly link={popup.url} onClose={() => setPopupsDisplayed([...popupsDisplayed, popup.id])}>
            {popup.images.length > 0 && (<img src={popup.images[0].path} alt={popup.title} />)}
            {popup.images.length === 0 && (<div>{popup.title}</div>)}
        </Modal>
    );
}

export default Popups;