import { PureComponent } from "react";
import "./Tabs.scss";

export default class Tabs extends PureComponent {
  render() {
    const { elements, active } = this.props;

    return (
      <div className="tabs__mobile-container">
        
        <div className="tabs">
          {elements.map(({ text, url, onClick }, index) => {
            var classNameTab =
              "tabs__item" + (active === index ? " tabs__item--active" : "");
            return (
              <div className={classNameTab} key={index}>
                {url && <a href={url}>{text}</a>}
                {onClick && <span style={{ cursor: 'pointer' }} onClick={onClick}>{text}</span>}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
