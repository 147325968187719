import { Component } from "react";
import "./Intro.scss";
import MainLink from "Components/Atoms/MainLink/index.jsx";

export default class Intro extends Component {
  render() {
    const {
      description,
      title,
      subtitle,
      type,
      titleOnly,
      small,
      link,
      linkUrl,
      ...etc
    } = this.props;

    let className =
      "intro" +
      (type ? " intro--" + type : "") +
      (titleOnly ? " intro--titleOnly" : "");

    var subtitleElement;
    if (subtitle) {
      subtitleElement = <h5>{subtitle}</h5>;
    }

    var linkElement;
    if (link && linkUrl) {
      linkElement = <MainLink url={linkUrl}>{link}</MainLink>;
    }

    var mainTitle;
    var titleElement;
    if (small) {
      titleElement = <h2>{title}</h2>;
    } else {
      titleElement = <h1>{title}</h1>;
    }
    if (titleOnly) {
      mainTitle = (
        <div className="grid">
          <div className="col22 s2">
            {subtitleElement}
            {titleElement}
            {linkElement}
          </div>
        </div>
      );
    } else {
      mainTitle = (
        <div className="grid">
          <div className="col10 s7">
            <h5>{subtitle}</h5>
            <h2>{title}</h2>
          </div>
          <div className="col10 s8">
            {description}
            <br />
            {linkElement}
          </div>
        </div>
      );
    }

    return (
      <div className={className} {...etc}>
        {mainTitle}
      </div>
    );
  }
}
