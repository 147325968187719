import { Component } from "react";
import "./TextField.scss";

export default class TextField extends Component {
  render() {
    const { placeholder, type, helpText, error, errorMessage, ...etc } = this.props;

    let className = "text-field" + (error ? " text-field--error" : "");

    var errorElement;
    if (error && errorMessage) {
      errorElement = (
        <div className="text-field__error-message">{errorMessage}</div>
      );
    }

    var helpElement;
    if (helpText) {
      helpElement = (
        <div className="text-field__help-text">{helpText}</div>
      );
    }

    return (
      <div className={className}>
        <input type={type ? type : "text"} placeholder={placeholder} {...etc} />
        {errorElement}
        {helpElement}
      </div>
    );
  }
}
