import axios from 'axios';
import Footer from "Components/Layout/Footer/index.jsx";
import Breadcrumbs from "Components/Layout/Breadcrumbs/index.jsx";
import Section from "Components/Layout/Section/index.jsx";
import SectionHero from "Components/Layout/SectionHero/index.jsx";
import Intro from "Components/Layout/Editorials/Intro/index.jsx";
import TechnicalData from "Components/Layout/Editorials/TechnicalData/index.jsx";
import Button from "Components/Atoms/Button/index.jsx";
import FormRow from "Components/Atoms/Forms/FormRow/index.jsx";
import FormField from "Components/Atoms/Forms/FormField/index.jsx";
import TextField from "Components/Atoms/Forms/TextField/index.jsx";
import Select from "Components/Atoms/Forms/Select/index.jsx";
import CheckboxGroup from "Components/Atoms/Forms/CheckboxGroup/index.jsx";
import Checkbox from "Components/Atoms/Forms/Checkbox/index.jsx";
import BenefitBox from 'Components/Molecules/Plan/BenefitBox';
import LinkingPos from "Components/Molecules/Payment/LinkingPos";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useFamily, useSavingsPlan } from "Components/Context/Parameters";
import { currency } from "Helpers/format";
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

const maritalStatus = [
  { value: 2, name: "Casado/a" },
  { value: 3, name: "Divorciado/a" },
  { value: 1, name: "Soltero/a" },
  { value: 4, name: "Viudo/a" },
];

function Subscription() {
  const params = useParams();
  const family = useFamily(params.family);
  const plan = useSavingsPlan(params.id);
  const [request, setRequest] = useState({
    name: "",
    lastname: "",
    marital_status_id: "1",
    spouse_name: "",
    address: "",
    telephone: "",
    dni: "",
    birthdate: "",
    email: "",
    code: '',
    payment_provider: "linkingpos",
  });
  const [error, setError] = useState(false);
  const [transaction, setTransaction] = useState(undefined);

  if (!plan) {
    return null;
  }

  const breadcrumbsList = [
    { id: 1, name: "Página de inicio", link: "/" },
    { id: 2, name: "Autoahorro", link: "/autoahorro" },
    { id: 3, name: plan.name, link: "/suscripcion" },
  ];

  const techDataList = [
    { id: 1, name: "Meses", value: plan.installments },
    { id: 2, name: "Operatoria", value: plan.integration },
    { id: 3, name: "Cuota inicial", value: currency(plan.first_installment) },
  ];

  const introTitle = [
    <strong key="1">Estas suscribiendote al plan </strong>,
    plan.name
  ];

  const step1 = [<strong key="1">Paso 1:</strong>, " Cupón de beneficios"];
  const step2 = [<strong key="2">Paso 2:</strong>, " Completá tus datos"];
  const step3 = [<strong key="3">Paso 3:</strong>, " Confirmación"];
  const step4 = [<strong key="4">Paso 4:</strong>, " Pago de la subscripción"];

  async function handleSubmit(e) {
    e.preventDefault();

    setTransaction(null);
    setError(false);

    try {
      const result = await axios.post("/api/vw/subscribe", { data: {
        ...request,
        plan_first_installment: plan.first_installment,
        plan_first_and_second_installment: plan.first_and_second_installment,
        vehicle: plan.model,
        plan_id: plan.id,
        plan_name: plan.name,
        brand_id: 1,
      } });

      if (result.data.transaction) {
        setTransaction(result.data.transaction);
      }
    } catch (e) {
      console.error(e);

      setError(true);
      setTransaction(undefined);
    }
  }

  function handleChange(e) {
    setRequest({ ...request, [e.currentTarget.name]: e.currentTarget.value });
  }

  function handleInstallmentChange(e) {
    let payment_amount = 0;
    switch (e.currentTarget.value) {
      case '1':
        payment_amount = plan.first_installment;
        break;
      case '2':
        payment_amount = plan.first_and_second_installment;
        break;
      default:
        payment_amount = 2000;
        break;
    }

    setRequest({ ...request, [e.currentTarget.name]: e.currentTarget.value, payment_amount });
  }

  const ready =
    request.name &&
    request.lastname &&
    request.marital_status_id &&
    request.telephone &&
    request.dni &&
    request.birthdate &&
    request.email &&
    request.accept_terms &&
    request.payed_installments !== '';

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - Subscripción a Autoahorro Volkswagen {family.name}</title>
        <meta name="description" content="Subscripción a Autoahorro Volkswagen - Planes de ahorro - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      <SectionHero title={introTitle} subtitle="Autoahorro Volkswagen" image={family.hero} />
      <TechnicalData dataList={techDataList} />
      <Breadcrumbs linkList={breadcrumbsList} withCurrent />


      <Section>
        <div className="grid">
          <div className="col col10 s7">
              <h5>Estas suscribiendote al plan {plan.name}</h5>
              <p><strong>{plan.model}</strong></p>
              <p>{plan.vehicle_features.join(' / ')}</p>
          </div>
        </div>
      </Section>
      {!transaction && (
        <>
          <Section>
            <Intro title={step1} subtitle="Suscripcion a Plan de Ahorro " />
            <BenefitBox plan={plan} onSuccess={(code) => setRequest({ ...request, code })} />
          </Section>
          <form onSubmit={handleSubmit}>
          <Section id="tus-datos">
            <Intro title={step2} />
            <div className="grid">
              <div className="col col10 s7">
                <FormRow>
                  <FormField label="Nombre" inline>
                    <TextField name="name" required onChange={handleChange} value={request.name} />
                  </FormField>
                  <FormField label="Apellido" inline>
                    <TextField name="lastname" required onChange={handleChange} value={request.lastname} />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField label="DNI" small inline>
                    <TextField
                      placeholder="Sin puntos"
                      name="dni"
                      required
                      onChange={handleChange}
                      value={request.dni}
                    />
                  </FormField>
                  <FormField label="Fecha de nacimiento" inline>
                    <TextField
                      placeholder="DD/MM/AAAA"
                      type="date"
                      name="birthdate"
                      required
                      onChange={handleChange}
                      value={request.birthdate}
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField label="Estado Civil" select inline>
                    <Select name="marital_status_id" required onChange={handleChange} value={request.marital_status_id}>
                      {maritalStatus.map(({ value, name }) => (
                        <option value={value} key={value}>
                          {name}
                        </option>
                      ))}
                    </Select>
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField label="Dirección">
                    <TextField name="address" required onChange={handleChange} value={request.address} />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField label="Email" large>
                    <TextField name="email" type="email" required onChange={handleChange} value={request.email} />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField label="Teléfono" mid>
                    <TextField type="tel" name="telephone" required onChange={handleChange} value={request.telephone} />
                  </FormField>
                </FormRow>
              </div>
            </div>
          </Section>

          <Section>
            <Intro title={step3} />
            <div className="grid">
              <div className="col col10 s7">
                <FormRow>
                  <FormField label="Seleccioná las cuotas a pagar" inline>
                    <Select name="payed_installments" required onChange={handleInstallmentChange} value={request.payed_installments}>
                      <option value="">Seleccionar importe a pagar</option>
                      <option value="1">Primera cuota {currency(plan.first_installment)}</option>
                      <option value="2">
                        Primera y segunda cuota {currency(plan.first_and_second_installment)}
                      </option>
                      <option value="0">
                        Quiero señar el plan (otro importe)
                      </option>
                    </Select>
                  </FormField>
                </FormRow>
                {request.payed_installments === '0' && (
                  <FormRow>
                    <FormField label="Ingresá el importe de la seña" large>
                      <TextField name="payment_amount" required onChange={handleChange} value={request.payment_amount} helpText="Te enviaremos por e-mail un link para que puedas continuar pagando tu reserva." />

                    </FormField>
                  </FormRow>
                )}
                <FormRow>
                  <p>
                    Dentro de las próximas 48 horas nos pondremos en contacto con vos para finalizar la subscripción.
                  </p>
                </FormRow>

                <FormRow>
                  <FormField label="Términos y condiciones">
                    <CheckboxGroup>
                      <Checkbox
                        text="Acepto los términos y condiciones"
                        required
                        name="accept_terms"
                        checked={request.accept_terms}
                        onChange={handleChange}
                        value={true}
                      />
                    </CheckboxGroup>
                  </FormField>
                </FormRow>
                {!ready && (
                  <FormRow>
                    <p style={{ color: "red" }}>
                      Completa el formulario y aceptá nuestros términos y condiciones para enviar la solicitud.
                    </p>
                  </FormRow>
                )}
                <FormRow>
                  {error && (
                    <FormRow>
                      <p style={{ color: "red" }}>
                        Hubo un error al procesar tu solicitud. Por favor intenta nuevamente o{" "}
                        <Link to="/contacto">contactanos</Link>.
                      </p>
                    </FormRow>
                  )}

                  <FormField>
                    {transaction === undefined && (
                      <Button type="submit" disabled={!ready}>
                        Pagar subscripción
                      </Button>
                    )}

                    {transaction === null && <p>Enviando solicitud...</p>}
                  </FormField>
                </FormRow>
              </div>
            </div>
          </Section>
      </form>
    </>)}

      {transaction && (
        <Section>
          <Intro title={step4} />
          <div className="grid">
            <div className="col col10 s7">
              <FormRow>
                <p>
                  Dentro de las próximas 48 horas nos pondremos en contacto con vos para finalizar la subscripción.
                </p>
              </FormRow>
              <FormRow>
                <LinkingPos amount={parseFloat(request.payment_amount)} transaction={transaction} />
              </FormRow>
            </div>
          </div>
        </Section>
      )}

      <Footer />
    </>
  );
}

export default Subscription;
