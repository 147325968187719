import axios from 'axios';
import Section from "Components/Layout/Section/index.jsx";
import Button from "Components/Atoms/Button/index.jsx";
import FormRow from "Components/Atoms/Forms/FormRow/index.jsx";
import FormField from "Components/Atoms/Forms/FormField/index.jsx";
import TextField from "Components/Atoms/Forms/TextField/index.jsx";
import TextArea from "Components/Atoms/Forms/TextArea/index.jsx";
import { useState } from 'react';

function ComplaintForm() {
  const [request, setRequest] = useState({
    name: '',
    lastname: '',
    telephone: '',
    message: ''
  });
  const [success, setSuccess] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);

  function handleChange(e) {
    setRequest({ ...request, [e.currentTarget.name]: e.currentTarget.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setSuccess(undefined);
    setSubmitting(true);

    try {
      await axios.post('/api/vw/complaint', { data: request });

      setSuccess(true);
    } catch (e) {
      console.error(e);

      setSuccess(false);
    }
  }

  const incomplete = !request.name || !request.lastname || !request.message || !request.telephone || !request.email;

  return (
    <Section>
      <div className="grid">
        <div className="col col6 s10">
          {!success && (
            <form onSubmit={handleSubmit}>
              <FormRow>
                <FormField label="Nombre" inline>
                  <TextField name="name" onChange={handleChange} value={request.name} required/>
                </FormField>
                <FormField label="Apellido" inline>
                  <TextField name="lastname" onChange={handleChange} value={request.lastname} required/>
                </FormField>
              </FormRow>
              <FormRow>
                <FormField label="Teléfono" inline>
                  <TextField type="tel" name="telephone" onChange={handleChange} value={request.telephone} required/>
                </FormField>
              </FormRow>
              <FormRow>
                <FormField label="Email">
                  <TextField type="email" name="email" onChange={handleChange} value={request.email} required/>
                </FormField>
              </FormRow>
              <FormRow>
                <FormField label="Mensaje">
                  <TextArea name="message" onChange={handleChange} value={request.message} />
                </FormField>
              </FormRow>
              <FormRow>
                <Button type="submit" disabled={submitting || incomplete}>Enviar mensaje</Button>
              </FormRow>
              {success === false && (
                <FormRow>
                  <p>Hubo un error enviando el mensaje.</p>
                </FormRow>
              )}
            </form>
          )}
          {success && (
            <p>Hemos recibido tu mensaje. Te estaremos respondiendo a la brevedad.</p>
          )}
        </div>
      </div>
    </Section>
  )
}

export default ComplaintForm;
