import { Component } from "react";
import "./TextArea.scss";

export default class TextArea extends Component {
  render() {
    const { placeholder, error, errorMessage, ...etc } = this.props;

    let className = "text-area" + (error ? " text-area--error" : "");

    var errorElement;
    if (error && errorMessage) {
      errorElement = (
        <div className="text-area__error-message">{errorMessage}</div>
      );
    }

    return (
      <div className={className}>
        <textarea placeholder={placeholder} {...etc} />
        {errorElement}
      </div>
    );
  }
}
