import "./Unit.scss";
import Button from "../../Atoms/Button/index.jsx";
import { Component } from "react";
import { currency } from "Helpers/format";
import { Link } from "react-router-dom";

export default class Unit extends Component {
  render() {
    const { family, vehicle, hidePrices } = this.props;

    var unitElement = (
      <div className="unit">
        <div className="grid">
          <div className="col col6 s5 col-order-2">
            <div className="unit__info">
              <strong>{vehicle.name}</strong>
              <br />
              {!hidePrices && (
                <>
                  {vehicle.special_discount > 0 && (
                    <div className="unit__info__price">
                      {currency(vehicle.fullprice)}
                      <div className="unit__info__price__discount">
                        {currency(vehicle.special_discount)}. Descuento especial
                      </div>
                    </div>
                  )}
                  {!vehicle.special_discount &&
                    vehicle.discount(
                      <div className="unit__info__price">
                        {currency(vehicle.fullprice)}
                        <div className="unit__info__price__discount">
                          {currency(vehicle.discount)}. Descuento web
                        </div>
                      </div>
                    )}
                </>
              )}
              <div className="unit__info__characteristics">
                <ul className="unit__characteristics">
                  <li className="unit__characteristics__item">
                    Color: {vehicle.color}
                  </li>
                  <li className="unit__characteristics__item">
                    Ubicación: {vehicle.location}
                  </li>
                  <li className="unit__characteristics__item">
                    Chasis: {vehicle.chassis}
                  </li>
                </ul>
              </div>
              <Link to={`/reservar/${vehicle.family_url_name}/${vehicle.id}`}>
                <Button>Reservar</Button>
              </Link>
            </div>
          </div>
          <div className="col col9 s11 col-order-1">
            <div className="unit__image">
              <img src={family.thumbnail} alt={vehicle.name} />
            </div>
          </div>
        </div>
      </div>
    );

    return <div>{unitElement}</div>;
  }
}
