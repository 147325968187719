import axios from 'axios';
import Footer from "Components/Layout/Footer/index.jsx";
import Breadcrumbs from "Components/Layout/Breadcrumbs/index.jsx";
import Section from "Components/Layout/Section/index.jsx";
import SectionHero from "Components/Layout/SectionHero/index.jsx";
import Intro from "Components/Layout/Editorials/Intro/index.jsx";
import TechnicalData from "Components/Layout/Editorials/TechnicalData/index.jsx";
import Button from "Components/Atoms/Button/index.jsx";
import FormRow from "Components/Atoms/Forms/FormRow/index.jsx";
import FormField from "Components/Atoms/Forms/FormField/index.jsx";
import TextField from "Components/Atoms/Forms/TextField/index.jsx";
import Select from "Components/Atoms/Forms/Select/index.jsx";
import CheckboxGroup from "Components/Atoms/Forms/CheckboxGroup/index.jsx";
import Checkbox from "Components/Atoms/Forms/Checkbox/index.jsx";
import BenefitBox from 'Components/Molecules/Plan/BenefitBox';
import LinkingPos from "Components/Molecules/Payment/LinkingPos";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFamily, useSavingsPlan } from "Components/Context/Parameters";
import { currency } from "Helpers/format";
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

const maritalStatus = [
  { value: 2, name: "Casado/a" },
  { value: 3, name: "Divorciado/a" },
  { value: 1, name: "Soltero/a" },
  { value: 4, name: "Viudo/a" },
];

function Subscription() {
  const params = useParams();
  const plan = useSavingsPlan(params.id);
  const family = useFamily(plan?.family.name);
  const [subscription, setSubscription] = useState(undefined);
  const [request, setRequest] = useState({
    payment_amount: 2000,
  });
  const [transaction, setTransaction] = useState(undefined);
  const [error, setError] = useState(false);

  useEffect(() => {
    axios.post("/api/vw/plans/subscription", { id: params.subscription })
      .then(response => {
        setSubscription(response.data.data);
      })
      .catch(() => setSubscription(null));
  }, []);

  if (subscription === undefined) {
    return null;
  }

  if (subscription === null) {
    return renderError('No pudimos encontrar tu subscripción.');
  }

  if (!plan) {
    return renderError('El plan no existe.');
  }

  const breadcrumbsList = [
    { id: 1, name: "Página de inicio", link: "/" },
    { id: 2, name: "Autoahorro", link: "/autoahorro" },
    { id: 3, name: plan.name, link: "/suscripcion" },
  ];

  const techDataList = [
    { id: 1, name: "Meses", value: plan.installments },
    { id: 2, name: "Operatoria", value: plan.integration },
    { id: 3, name: "Cuota inicial", value: currency(plan.first_installment) },
  ];

  const introTitle = [
    <strong key="1">Estas suscribiendote al plan </strong>,
    plan.name
  ];

  const step1 = [<strong key="1">Paso 1:</strong>, " Cupón de beneficios"];
  const step2 = [<strong key="2">Paso 2:</strong>, " Selección de importe"];
  const step3 = [<strong key="3">Paso 3:</strong>, " Pago de la reserva"];

  async function handleSubmit(e) {
    e.preventDefault();

    setTransaction(null);
    setError(false);

    let payedInstallments = 0;
    let currentAmount = subscription.payment_amount + parseFloat(request.payment_amount);

    if (currentAmount >= subscription.plan_first_and_second_installment) {
      payedInstallments = 2;
    } else if (currentAmount >= subscription.plan_first_installment) {
      payedInstallments = 1;
    }

    try {
      const result = await axios.post("/api/vw/subscribe", { data: {
        id: subscription.id,
        ...request,
        payed_installments: payedInstallments,
        brand_id: 1,
      } });

      if (result.data.transaction) {
        setTransaction(result.data.transaction);
      }
    } catch (e) {
      console.error(e);

      setError(true);
      setTransaction(undefined);
    }
  }

  function handleChange(e) {
    setRequest({ ...request, [e.currentTarget.name]: e.currentTarget.value });
  }

  const ready = request.payment_amount &&
    request.accept_terms;

  const firstInstallmentPendingAmount = subscription.plan_first_installment - subscription.payment_amount;
  const firstAndSecondInstallmentPendingAmount = subscription.plan_first_and_second_installment - subscription.payment_amount;
  const subscriptionIsPayed = firstInstallmentPendingAmount <= 0 && firstAndSecondInstallmentPendingAmount <= 0;

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - Subscripción a Autoahorro Volkswagen {family.name}</title>
        <meta name="description" content="Subscripción a Autoahorro Volkswagen - Planes de ahorro - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      <SectionHero title={introTitle} subtitle="Autoahorro Volkswagen" image={family.hero} />
      <TechnicalData dataList={techDataList} />
      <Breadcrumbs linkList={breadcrumbsList} withCurrent />

      <Section>
        <div className="grid">
          <div className="col col10 s7">
              <h5>Estas suscribiendote al plan {plan.name}</h5>
              <p><strong>{plan.model}</strong></p>
              <p>{plan.vehicle_features.join(' / ')}</p>
          </div>
        </div>
      </Section>
      {!transaction && (
        <>
          <Section>
            <Intro title={step1} subtitle="Suscripcion a Plan de Ahorro " />
            {subscription.coupon && <BenefitBox plan={plan} planCoupon={subscription.coupon} />}
            {!subscription.coupon && (
              <div className="grid">
                <div className="col col10 s7">
                  <FormRow>
                    <p>No utilizaste un cupón de beneficios.</p>
                  </FormRow>
                </div>
              </div>
            )}
          </Section>
          <form onSubmit={handleSubmit}>

          <Section id="tus-datos">
            <Intro title={step2} />
            <div className="grid">
              <div className="col col10 s7">
                <FormRow>
                  <FormField label="Ingresá el importe que querés pagar" large>
                    <TextField name="payment_amount" required onChange={handleChange} value={request.payment_amount} helpText="Te enviaremos por e-mail un link para que puedas continuar pagando tu reserva." error={request.payment_amount > firstAndSecondInstallmentPendingAmount} errorMessage={`Ingresá hasta ${currency(firstAndSecondInstallmentPendingAmount)} para abonar las primeras dos cuotas del plan.`} />
                  </FormField>
                </FormRow>
                <FormRow>
                  <p>
                    Saldo pendiente para la primera cuota: <strong>{firstInstallmentPendingAmount > 0 ? currency(firstInstallmentPendingAmount) : 'sin saldo pendiente'}</strong>.<br />
                    Saldo pendiente para la primera y segunda cuota: <strong>{firstAndSecondInstallmentPendingAmount ? currency(firstAndSecondInstallmentPendingAmount) : 'sin saldo pendiente'}</strong>.
                  </p>
                </FormRow>

                {subscriptionIsPayed ? (
                  <FormRow>
                    <h3>
                      Tu reserva se encuentra paga.
                    </h3>
                  </FormRow>
                ) : (
                  <>
                    <FormRow>
                      <FormField label="Términos y condiciones">
                        <CheckboxGroup>
                          <Checkbox
                            text="Acepto los términos y condiciones"
                            required
                            name="accept_terms"
                            checked={request.accept_terms}
                            onChange={handleChange}
                            value={true}
                          />
                        </CheckboxGroup>
                      </FormField>
                    </FormRow>
                    {!ready && (
                      <FormRow>
                        <p style={{ color: "red" }}>
                          Completa el formulario y aceptá nuestros términos y condiciones para enviar la solicitud.
                        </p>
                      </FormRow>
                    )}
                    <FormRow>
                      {error && (
                        <FormRow>
                          <p style={{ color: "red" }}>
                            Hubo un error al procesar tu solicitud. Por favor intenta nuevamente o{" "}
                            <Link to="/contacto">contactanos</Link>.
                          </p>
                        </FormRow>
                      )}

                      <FormField>
                        {transaction === undefined && (
                          <Button type="submit" disabled={!ready}>
                            Pagar subscripción
                          </Button>
                        )}

                        {transaction === null && <p>Enviando solicitud...</p>}
                      </FormField>
                    </FormRow>
                  </>
                )}
              </div>
            </div>
          </Section>
      </form>
    </>)}

      {transaction && (
        <Section>
          <Intro title={step3} />
          <div className="grid">
            <div className="col col10 s7">
              <FormRow>
                <p>
                  Dentro de las próximas 48 horas nos pondremos en contacto con vos para finalizar la subscripción.
                </p>
              </FormRow>
              <FormRow>
                <LinkingPos amount={parseFloat(request.payment_amount)} transaction={transaction} />
              </FormRow>
            </div>
          </div>
        </Section>
      )}

      <Footer />
    </>
  );
}

function renderError(message) {
  return (
    <Section>
      <div className="grid">
        <div className="col col10 s7">
          <h3>{message}</h3>
        </div>
      </div>
    </Section>
  );
}

export default Subscription;
