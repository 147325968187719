import axios from "axios";
import Family from "Models/Family";
import Subfamily from "Models/Subfamily";
import SavingsPlan from "Models/SavingsPlan";
import StockVehicle from "Models/StockVehicle";
import ServiceOffer from "Models/ServiceOffer";
import { createContext, useContext, useState, useEffect } from "react";

const ParametersContext = createContext({ parameters: null, families: [] });

const API_URL = "https://sistema.yacopini.com.ar/intranet/webservice/v1/vw";

const ParametersContextProvider = ({ children }) => {
  const [parameters, setParameters] = useState(null);
  const [families, setFamilies] = useState([]);
  const [offers, setOffers] = useState([]);
  const [serviceOffers, setServiceOffers] = useState([]);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    axios
      .get("/api/vw/parameters")
      .then((response) => setParameters(response.data))
      .catch((e) => console.error(e));

    axios
      .get(`${API_URL}/stock/families`)
      .then((response) =>
        setFamilies(response.data.data.map((data) => new Family(data)))
      )
      .catch((e) => console.error(e));

    axios
      .get(`${API_URL}/offers`)
      .then((response) =>
        setOffers(response.data.data.map((data) => new StockVehicle(data)))
      )
      .catch((e) => console.error(e));

    axios
      .get(`/api/vw/service-promotions`)
      .then((response) => {
        const serviceOffers = response.data.data.filter(({ price_default, price_promotion}) => price_default !== price_promotion);

        setServiceOffers(
          serviceOffers.map((data) => new ServiceOffer(data))
        );
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (!families.length) {
      return;
    }

    axios
      .get(`${API_URL}/plan/find`)
      .then((response) => {
        const plans = response.data.data.map((data) => new SavingsPlan(data, families));
        plans.sort((a, b) => a.priority - b.priority);

        setPlans(plans);
      })
      .catch((e) => console.error(e));
  }, [families]);

  if (!parameters || !families.length) {
    return <div>Bienvenido a Yacopini Süd</div>;
  }

  return (
    <ParametersContext.Provider
      value={{ parameters, families, offers, serviceOffers, plans }}
    >
      {children}
    </ParametersContext.Provider>
  );
};

export const useParameter = (name) => {
  const { parameters } = useContext(ParametersContext);

  if (!parameters) {
    console.log("Parameters are not ready");

    return "";
  }

  if (!parameters[name]) {
    console.log(`Parameter ${name} does not exist`);

    return "";
  }

  return parameters[name];
};

export const useFamilies = () => {
  const { families } = useContext(ParametersContext);

  return families;
};

export const useFamily = (name) => {
  const { families } = useContext(ParametersContext);

  if (!name) {
    return null;
  }

  const altName = name.replace("-", " ").toLowerCase();

  return families.find(
    (family) =>
      family.name.toLowerCase() === name.toLowerCase() ||
      family.name.toLowerCase() === altName
  );
};

export const useSavingsPlan = (id) => {
  const { plans } = useContext(ParametersContext);

  return plans.find( plan => plan.id === id);
};

export const useSubfamilies = ({ name } = {}) => {
  const [subfamilies, setSubfamilies] = useState([]);
  const { families } = useContext(ParametersContext);

  useEffect(() => {
    const family = families.find(
      (family) => family.name.toLowerCase() === name.toLowerCase()
    );

    if (!family) {
      return;
    }

    axios
      .get(`${API_URL}/stock/subfamilies?family_id=${family.id}`)
      .then((response) =>
        setSubfamilies(response.data.data.map((data) => new Subfamily(data)))
      )
      .catch((e) => console.error(e));
  }, [name, families]);

  return subfamilies;
};

export const useSubfamily = (family, name) => {
  const [subfamily, setSubfamily] = useState(null);
  const subfamilies = useSubfamilies(family);
  const subfamilyName = name ? name.replace("-", " ") : "";

  useEffect(() => {
    if (!subfamilies || !subfamilyName) {
      return;
    }

    subfamilies.forEach((subfamily) => {
      if (subfamily.name.toLowerCase() === subfamilyName) {
        setSubfamily(subfamily);
      }
    });
  }, [subfamilyName, subfamilies]);

  return subfamily;
};

export const useSavingPlans = () => {
  const { plans } = useContext(ParametersContext);

  return plans;
};

export const useOffers = (family) => {
  const { offers } = useContext(ParametersContext);

  if (!family) {
    return offers;
  }

  return offers.filter((offer) => offer.family_id === family.id);
};

export const useStock = (family, subfamily) => {
  const [stock, setStock] = useState([]);

  useEffect(() => {
    if (!subfamily) {
      return;
    }

    axios
      .get(
        `${API_URL}/stock/stock?family_id=${family.id}&subfamily_id=${subfamily.id}`
      )
      .then((response) =>
        setStock(response.data.data.map((data) => new StockVehicle(data)))
      )
      .catch((e) => console.error(e));
  }, [family, subfamily]);

  return stock;
};

export const useStockItem = (id) => {
  const [stock, setStock] = useState(undefined);

  useEffect(() => {
    axios
      .get(`${API_URL}/stock/stock?id=${id}`)
      .then((response) => {
        if (response.data.data) {
          setStock(new StockVehicle(response.data.data));
          return;
        }

        setStock(null);
      })
      .catch((e) => {
        console.error(e);
        setStock(null);
      });
  }, [id]);

  return stock;
};

export const useServiceOffers = () => {
  const { serviceOffers } = useContext(ParametersContext);

  return serviceOffers;
};

export default ParametersContextProvider;
