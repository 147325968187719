import { Component } from "react";
import "./SectionHero.scss";
import Responsive from "react-responsive";

export default class SectionHero extends Component {
  render() {
    const { subtitle, title, description, image, type } = this.props;
    const Mobile = (props) => <Responsive {...props} maxWidth={540} />;

    let className = "section-hero" + (type ? " section-hero--imageOnly" : "");

    var descriptionElement;
    if (description) {
      descriptionElement = <p className="p">{description}</p>;
    }

    var subtitleElement;
    if (subtitle) {
      subtitleElement = <h5>{subtitle}</h5>;
    }

    var imageElement;
    if (image) {
      if (type === "imageOnly") {
        imageElement = (
          <div className="section-hero__image section-hero__image--only">
            <img src={image} alt={title} />
          </div>
        );
      } else {
        imageElement = (
          <div className="section-hero__image col12">
            <img src={image} alt={title} />
            <Mobile>
              <div className="section-hero__image__text">
                {subtitleElement}
                <h2>{title}</h2>
              </div>
            </Mobile>
          </div>
        );
      }
    }

    var heroElement;
    if (type === "imageOnly") {
      heroElement = (
        <div className={className} id="section-hero">
          {imageElement}
        </div>
      );
    } else {
      heroElement = (
        <div className={className} id="section-hero">
          <div className="grid">
            {imageElement}

            <div className="section-hero__container col8 s15">
              <div className="section-hero__container__wrapper">
                {subtitleElement}
                <h1>{title}</h1>
                {descriptionElement}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <>{heroElement}</>;
  }
}
