import Button from "../../Atoms/Button/index.jsx";
import TechnicalData from "Components/Layout/Editorials/TechnicalData/index.jsx";
import { Component } from "react";
import { currency } from "Helpers/format";
import { Link } from 'react-router-dom';
import "./Plan.scss";

export default class Plan extends Component {
  render() {
    const { url, highlighted, plan } = this.props;

    const color = plan.family?.thumbnailColor;

    let className =
      "plan" +
      (color ? " plan--" + color : "") +
      (highlighted ? " plan--highlighted" : "");

    let buttonText = "Suscribirse";
    var planElement;
    let techDataList = [
      { id: 1, name: "Meses", value: plan.installments, measure: "" },
      { id: 2, name: "Operatoria", value: plan.integration, measure: "" },
      {
        id: 3,
        name: "Cuota Inicial",
        value: currency(plan.first_installment),
        measure: "*",
      },
    ];

    planElement = (
      <div className={className}>
        <div className="plan__hero">
          <div className="plan__image">
            <img src={plan.family?.thumbnail} alt={plan.model} />
          </div>
          <div className="plan__name">
            Plan: {plan.name}
            <div className="plan__model">{plan.model}</div>
          </div>
        </div>
        <div className="plan__content">
          <TechnicalData small center dataList={techDataList} />
          <p className="p p-s">{plan.description}</p>
          <br />

          <div className="plan__content__button">
            <Link to={url}>
              <Button small color="ghost">
                {buttonText}
              </Button>
            </Link>
          </div>
          <div className="model__content__link">
            <p><small>{plan.model}</small></p>
            <p><small>{plan.vehicle_features.join(' / ')}</small></p>
          </div>
        </div>
      </div>
    );

    return <div>{planElement}</div>;
  }
}
