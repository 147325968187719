import { useState, useEffect, useCallback } from "react";
import Menu from "../Menu/index.jsx";
import { Link, useLocation } from "react-router-dom";
import "./Meta.scss";

function Meta({ children }) {
  const location = useLocation();
  const [state, setState] = useState({
    metaState: location.pathname === "/" ? "2" : "1",
    menuOpen: false,
    scrolled: false,
  });

  const listenToScroll = useCallback(() => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const winSize = window.innerHeight - 64;

    const newState = { ...state };

    newState.scrolled = winScroll > 0 ? true : false;

    if (window.location.pathname === "/") {
      newState.metaState = winScroll > winSize ? "1" : "2";
    } else {
      newState.metaState = "1";
    }

    setState(newState);
  }, []); // eslint-disable-line

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);

    return () => window.removeEventListener("scroll", listenToScroll);
  }, []); // eslint-disable-line

  useEffect(() => {
    listenToScroll();
  }, [location, location.pathname, listenToScroll]);

  function toogleMenu() {
    setState({ ...state, menuOpen: !state.menuOpen });
  }

  let className =
    "meta" +
    (state.metaState ? " meta-state-" + state.metaState : "") +
    (state.scrolled ? " meta--scrolled" : "");

  return (
    <div className={className}>
      <Menu open={state.menuOpen} toggleMenu={toogleMenu}></Menu>
      <div className="meta__background"></div>
      <div className="meta__content ">
        <div className="meta__content__menu">
          <button aria-hidden="true" onClick={toogleMenu}>
            <div className="meta__content__menu__icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
              >
                <path d="M0 4h24v2H0zM0 11h14v2H0zM0 18h20v2H0z"></path>
              </svg>
            </div>
            <div className="meta__content__menu__text">
              <div>Menú</div>
            </div>
          </button>
        </div>
        <div className="meta__content__main">{children} </div>
        <div className="meta__content__right"></div>
      </div>
      <div className="meta__separator ">
        <div className="meta__separator__border "></div>
        <div className="meta__separator__vw ">
          <Link to="/">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
            >
              <path d="M12 22.586c-5.786 0-10.543-4.8-10.543-10.586 0-1.2.214-2.357.6-3.471l6.172 12c.085.171.171.3.385.3.215 0 .3-.129.386-.3l2.871-6.386c.043-.086.086-.129.129-.129.086 0 .086.086.129.129l2.914 6.386c.086.171.171.3.386.3.214 0 .3-.129.385-.3l6.172-12c.385 1.071.6 2.228.6 3.471-.043 5.786-4.8 10.586-10.586 10.586zm0-13.329c-.086 0-.086-.086-.129-.128l-3.3-7.115a10.117 10.117 0 016.858 0l-3.3 7.115c-.043.042-.043.128-.129.128zm-3.471 7.714c-.086 0-.086-.085-.129-.128L3 6.47c.943-1.542 2.314-2.828 3.9-3.728l3.814 8.228c.086.172.172.215.3.215h1.972c.128 0 .214-.043.3-.215l3.771-8.228c1.586.9 2.957 2.186 3.9 3.728L15.6 16.843c-.043.086-.086.128-.129.128-.085 0-.085-.085-.128-.128L13.286 12.3c-.086-.171-.172-.214-.3-.214h-1.972c-.128 0-.214.043-.3.214l-2.057 4.543c-.043.043-.043.128-.128.128zM12 24c6.643 0 12-5.357 12-12S18.643 0 12 0 0 5.357 0 12s5.357 12 12 12z"></path>
            </svg>
          </Link>
        </div>
        <div className="meta__separator__border "></div>
      </div>
    </div>
  );
}

export default Meta;
