import { Component } from "react";
import Button from "../../Atoms/Button/index.jsx";
import "./Stage.scss";

export default class Stage extends Component {
  render() {
    const { title, description, cta, image, url } = this.props;

    let className = "stage";

    var descriptionElement;
    if (description) {
      descriptionElement = (
        <p className="p p-b stage__description">{description}</p>
      );
    }

    var ctaElement;
    if (cta && !url) {
      ctaElement = <Button color="white">{cta}</Button>;
    }

    if (url) {
      ctaElement = (
        <a href={url}>
          <Button color="white">{cta || "Ver más"}</Button>
        </a>
      );
    }

    var imageElement;
    if (image) {
      imageElement = (
        <div className="stage__image">
          <img src={image} alt={title} />
        </div>
      );
    }

    return (
      <div className={className} id="stage">
        {imageElement}
        <div className="stage__container">
          <div className="grid">
            <div className="col10 s4">
              <h1>{title}</h1>
              {descriptionElement}
              {ctaElement}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
