import { Component } from "react";
import "./CheckboxGroup.scss";

export default class CheckboxGroup extends Component {
  render() {
    const { children, error, errorMessage } = this.props;

    let className = "checkbox-group" + (error ? " checkbox-group--error" : "");

    var errorElement;
    if (error && errorMessage) {
      errorElement = (
        <div className="checkbox-group__error-message">{errorMessage}</div>
      );
    }

    return (
      <div className={className}>
        {children}
        {errorElement}
      </div>
    );
  }
}
