import Button from "../../Atoms/Button/index.jsx";
import { currency } from 'Helpers/format';
import "./Accesory.scss";

function Accessory({ accessory, addToCart, inCart }) {
  return (
    <div className="accesory">
      <div className="accesory__container">
        <div className="accesory__image">
          <img src={accessory.image} width="100%" alt={accessory.name} />
        </div>
        <div className="accesory__name">
          <h4>
            <strong>{currency(accessory.final_price)}</strong>
          </h4>
          {accessory.has_discount && (
            <small>Precio normal {currency(accessory.price)}</small>
          )}
          <h5>{accessory.name}</h5>
        </div>
        <div className="accesory__description">
          <p className="p p-s">
            <span className="accesory__code">{accessory.code}</span>
            <br />
          </p>
          <div className="p-s" dangerouslySetInnerHTML={{ __html: accessory.description }} />
        </div>
        <div className="accesory__action">
          <Button color="ghost" onClick={() => addToCart(accessory)}>Agregar al carrito</Button>
          {inCart.length === 1 && <p className="p-s"><br />Agregado al carrito.</p>}
          {inCart.length > 1 && <p className="p-s"><br />Agregados {inCart.length} al carrito.</p>}
        </div>
      </div>
    </div>
  );
}

export default Accessory;
