import Footer from "Components/Layout/Footer/index.jsx";
import Breadcrumbs from "Components/Layout/Breadcrumbs/index.jsx";
import SectionHero from "Components/Layout/SectionHero/index.jsx";
import Intro from "Components/Layout/Editorials/Intro/index.jsx";
import Image1 from "images/photos/Stages/16-9_transparent-on-quality-and-price.jpg";
import { Helmet } from "react-helmet";
import ComplaintForm from "Components/Molecules/ComplaintForm";
import { Link } from 'react-router-dom';

function Contact() {
  const writeusTitle = <strong>Canal de denuncias</strong>;
  const stageDesc = "En Yacopini Süd nuestro compromiso es con vos. Abajo encontrarás nuestro canal de denuncias.";
  const breadcrumbsList = [
    { id: 1, name: "Página de inicio", link: "/" },
    { id: 2, name: "Código de conducta", link: "https://vwyacopini.com.ar/public/vw/codigo.pdf" },
    { id: 3, name: "Canal de denuncias", link: "/contacto" },
  ];

  const contactTitle = <strong> Yacopini Süd</strong>;

  const description = (
    <div>
      <p>El canal de denuncias debe ser utilizado exclusivamente para reportar incumplimientos de leyes o normativas internas. </p>
      <p>Para casos relacionados con atención al cliente en relación a nuestros productos o servicios, por favor utilizar los medios enumerados <Link to="/contacto">aqui</Link>.</p>
      <p>Adicionalmente podés encontrar nuestro código de conducta en el <a href="https://vwyacopini.com.ar/public/vw/codigo.pdf" target="_blank" rel="noopener noreferrer">siguiente link</a>.</p>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - Integridad y Compliance</title>
        <meta name="description" content="Canal de denuncias - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      <SectionHero
        title={contactTitle}
        image={Image1}
        description={stageDesc}
      />
      <Breadcrumbs linkList={breadcrumbsList} withCurrent />
      <Intro title={writeusTitle} description={description} />
      <ComplaintForm />

      <Footer />
    </>
  );
}

export default Contact;
