import { getImage, getHero, getDiscount, getWarranty } from "Helpers/image";
import { url } from "Helpers/format";

export default class Family {
  offers = [];

  constructor({ id, name, description, discount, warranty }) {
    this.id = parseInt(id, 10);
    this.name = name;
    this.description = description;
    this.discount = discount;
    this.warranty = warranty;
  }

  get hero() {
    return getHero(this);
  }

  get thumbnail() {
    return getImage(this);
  }

  get thumbnailColor() {
    const name = this.name.toLowerCase();

    switch (name) {
      case "amarok":
        return "blue";
      case "gol":
        return "black";
      case "golf":
        return "red";
      case "nivus":
        return "red";
      case "nuevo touareg":
      case "touareg":
        return "black";
      case "polo":
        return "red";
      case "saveiro":
        return "tin";
      case "tcross":
        return "tin";
      case "tiguan":
        return "black";
      case "up!":
        return "stone";
      case "vento":
        return "stone";
      case "virtus":
        return "tin";
      case "voyage":
        return "black";
      default:
        console.log(`Image for ${name} not found.`);

        return "";
    }
  }

  get discount_image() {
    return getDiscount(this);
  }

  get warranty_image() {
    return getWarranty(this);
  }

  get url_name() {
    return url(this.name);
  }
}
