import { Component } from "react";
import "./TwoColumns.scss";

export default class TwoColumns extends Component {
  render() {
    const { column1, column2, inverted } = this.props;

    let className = "two-column" + (inverted ? " two-column--" + inverted : "");

    var layoutElement;
    if (inverted) {
      layoutElement = (
        <div className="grid">
          <div className="col6 s3 two-column__column1">{column1}</div>
          <div className="col12 s12 two-column__column2">{column2}</div>
        </div>
      );
    } else {
      layoutElement = (
        <div className="grid">
          <div className="col12 s3 two-column__column1">{column1}</div>
          <div className="col6 s17 two-column__column2">{column2}</div>
        </div>
      );
    }

    return <div className={className}>{layoutElement}</div>;
  }
}
