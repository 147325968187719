import Stage from "Components/Layout/Stage/index.jsx";
import Footer from "Components/Layout/Footer/index.jsx";
import Breadcrumbs from "Components/Layout/Breadcrumbs/index.jsx";
import Button from "Components/Atoms/Button/index.jsx";
import Basic from "Components/Layout/Editorials/Basic/index.jsx";
import Image1 from "images/photos/Stages/16-9_Parts.jpg";
import Image2 from "images/photos/Stages/4-3_FAQs_6.jpg";
import ServiceFinder from "Components/Molecules/Service/Finder";
import { useParameter } from "Components/Context/Parameters";
import {Helmet} from "react-helmet";

function Services() {
  const services = useParameter("services");

  let stageTitle = (
    <>
      Nuestros servicios
      <br />
      <strong> de taller</strong>
    </>
  );

  let stageDesc = [
    "Aquí podés encontrar todos los servicios que te brindamos para mantener tu auto siempre en las mejores condiciones. Conoce los precios, tiempos y detalles de cada uno para saber todo lo que hacen nuestros mecánicos.",
  ];

  let breadcrumbsList = [
    { id: 1, name: "Página de inicio", link: "/" },
    { id: 2, name: "Postventa", link: "/Postventa" },
  ];

  let basicTitle = (
    <>
      Solicitá un
      <strong> turno de servicio</strong>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - Servicios de postventa</title>
        <meta name="description" content="Servicios oficiales de posventa Volkswagen - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      <Stage title={stageTitle} image={Image1} description={stageDesc} />
      <Breadcrumbs linkList={breadcrumbsList} withCurrent />
      <Basic
        layout="inline-9"
        image={Image2}
        title={basicTitle}
        subtitle="Taller Yacopini Süd"
        description="Reservá un turno para realizar cualquier servicio que necesite tu vehículo. Ingresá tus datos y elegí el día y el horario que mejor se ajuste a sus posibilidades. Lo puedes hacer en solo 5 minutos."
      >
        <a
          href="https://territorioyacopini.com.ar/solicitar-turno/volkswagen"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>Pedir Turno</Button>
        </a>
      </Basic>

      {services && (
        <ServiceFinder
          engines={services.engines}
          transmissions={services.transmissions}
          vehicles={services.vehicles}
        />
      )}

      <Footer />
    </>
  );
}

export default Services;
