import Stage from "Components/Layout/Stage/index.jsx";
import Footer from "Components/Layout/Footer/index.jsx";
import MainLink from "Components/Atoms/MainLink/index.jsx";
import Intro from "Components/Layout/Editorials/Intro/index.jsx";
import Basic from "Components/Layout/Editorials/Basic/index.jsx";
import ThreeColumns from "Components/Layout/Editorials/ThreeColumns/index.jsx";
import MoodGallery from "Components/Layout/Editorials/MoodGallery/index.jsx";
//images
import Image1 from "images/photos/Stages/16_9_e-Service.jpg";
import Image4 from "images/photos/Stages/16-9_Ready-to-move_Still.jpg";
import Image6 from "images/photos/Stages/ST_16-9_Owners-users.jpg";
import Image7 from "images/photos/Stages/Techniklexikon_mood-gallery6_19-6_f-cc.jpg";
import ImageOportunities from "images/photos/Stages/16-9_transparent-on-quality-and-price.jpg";
import ImageWebDiscount from "images/photos/webdiscount.jpg";
import ImagePlans from "images/photos/Stages/Techniklexikon_mood-gallery6_19-6_f-cc.jpg";
import { useParameter } from "Components/Context/Parameters";
import {Helmet} from "react-helmet";
import StageImage from "images/photos/Stages/Techniklexikon_mood-gallery6_19-6_f-cc.jpg";

function Home() {
  const hero = useParameter("hero");

  const stageTitle = (
    <>
      Bienvenidos a
      <br />
      <strong> Yacopini Süd</strong>
    </>
  );

  const introTitle = (
    <>
      Hay un Volkswagen
      <br />
      <strong> para vos</strong>
    </>
  );
  const moodTitle = (
    <>
      Visitanos en <br />{" "}
      <strong> San Martin Sur 600, Godoy Cruz, Mendoza.</strong>
    </>
  );
  const webDiscountTitle = (
    <>
      <strong>Descuentos Web</strong>
    </>
  );
  const oportunitiesTitle = (
    <>
      <strong>Oportunidades</strong>
    </>
  );
  const plansTitle = (
    <>
      <strong>Autoahorro</strong>
    </>
  );

  const basicWebDiscount = (
    <Basic
      layout="basic-12"
      image={ImageWebDiscount}
      title={webDiscountTitle}
      description="Si reservás tu 0KM a través de nuestra web, tenés beneficios especiales. Buscá los autos marcados con descuento web y disfrutá de importantes descuentos en tu nuevo 0KM."
    >
      <MainLink icon url="/oportunidades">
        Ver los descuentos
      </MainLink>
    </Basic>
  );

  const basicOportunities = (
    <Basic
      layout="basic-6-square"
      image={ImageOportunities}
      title={oportunitiesTitle}
      description="Todos los días publicamos descuentos especiales en determinados modelos. Visitanos diariamente y no te pierdas de adquirir tu 0KM a un precio increible."
    >
      <MainLink icon url="/oportunidades">
        Ver todas las oportunidades
      </MainLink>
    </Basic>
  );

  const basicPlans = (
    <Basic
      layout="basic-12"
      image={ImagePlans}
      title={plansTitle}
      description="Autoahorro Volkswagen te da la posibilidad de acceder, mediante el pago de cuotas mensuales en pesos, sujetas a variaciones de lista de precios, y de bajo monto, sin anticipos ni requisitos de ingreso, a los vehículos de la amplia gama de modelos que Volkswagen pensó para vos."
    >
      <MainLink icon url="/autoahorro">
        Ver los planes
      </MainLink>
    </Basic>
  );

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - Concesionario oficial Volkswagen</title>
        <meta name="description" content="Concesionario oficial Volkswagen - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      {hero && hero.images.length > 0 && (
        <Stage
          title={stageTitle}
          image={`https://vwyacopini.com.ar${hero?.images[0].path}`}
          description={`${hero.title} ${hero.subtitle}`}
          url={hero.url}
        />
      )}
      {!hero || hero.images.length === 0 && (
        <Stage
          title={stageTitle}
          image={StageImage}
          description="Yacopini Süd"
          url="/modelos"
        />
      )}

      <Intro title={introTitle} titleOnly />

      <ThreeColumns
        column1={basicWebDiscount}
        column2={basicOportunities}
        column3={basicPlans}
      />

      <MoodGallery
        image1={Image1}
        image2={Image7}
        image3={Image6}
        image4={Image4}
        version="threePicturesSquare"
        title={moodTitle}
      />

      <Footer />
    </>
  );
}

export default Home;
